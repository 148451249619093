var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-loader"),
      _c(
        "v-card-text",
        { staticClass: "mt-5 mb-3 px-12" },
        [
          _vm.isEditMode
            ? _c(
                "v-card",
                { staticClass: "mb-8", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-img", {
                        key: "asd",
                        staticClass: "profile-picture",
                        attrs: {
                          src: _vm.url,
                          "aspect-ratio": "1.5",
                          height: _vm.height
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _vm._.isNil(_vm.url)
                                    ? _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.anonymousImage,
                                              alt: "anonymous"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1923218364
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("app-form", {
            attrs: {
              fields: _vm.fields,
              "submit-button": _vm.submitButton,
              "disable-cancel-button": "",
              footerClass: "col-12 text-center mt-5"
            },
            on: {
              "file-attached": _vm.handleAttach,
              submitted: _vm.handleSubmit
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }